<template>
  <div>
    <GraphsMenu menu="generics-branches"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Marca/Sucursales Rezagadas
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
              </CCol>
              <CCol md="4" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>
              <CCol md="5" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="genericSelect" 
                  :options="genericsOptions"
                  @change="changeFilters"
                />   
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12" class="table-support">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="visits"
              :fields="fields"
              :items-per-page="20"
              :dark="false"
              pagination
              style="width: 100%; font-size: 10px;"
            >
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #store_name="{item}"><td>{{item.store_name}}</td></template>
              <template #branch_name="{item}"><td>{{item.branch_name}}</td></template>
              <template #collaborator_route="{item}"><td>{{item.collaborator_route}}</td></template>
              <template #visit_date="{item}">
                <td class="text-center w70">
                  {{item.visit_date | date }}
                </td>
              </template>
              <template #status="{item}">
                <td class="text-center w50">
                  {{item.status}}
                </td>
              </template>
              <template #validation_date="{item}">
                <td class="text-center w70">
                  {{item.validation_date | date }}
                </td>
              </template>
              <template #validation_comments="{item}"><td>{{item.validation_comments}}</td></template>
              <template #collaborator_name="{item}"><td>{{item.collaborator_name}}</td></template>
              <template #collaborator_account="{item}"><td>{{item.collaborator_account}}</td></template>
              <template #collaborator_bank="{item}"><td>{{item.collaborator_bank}}</td></template>
              <template #collaborator_salary="{item}"><td>${{item.collaborator_salary}}</td></template>
              
            </CDataTable>
          </CCol>     
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import GraphsMenu from './GraphsMenu.vue';

export default {
  name: 'GenericsBranches',
  components: {    
    GraphsMenu
  },
  data () {
    return {
      code: 'general',
      visits: [],
      fields: [
          {key: 'customer_name', label: 'Marca'},
          {key: 'store_name', label: 'Cadena'},
          {key: 'branch_name', label: 'Sucursal'},
          {key: 'collaborator_route', label: 'Ruta Rezagada'},
          {key: 'visit_date', label: 'Fecha Visita'},
          {key: 'status', label: 'Estado Revisión'},
          {key: 'validation_date', label: 'Fecha Revisión'},
          {key: 'validation_comments', label: 'Comentario de Revisión'},
          {key: 'collaborator_name', label: 'Promotor' ,_style:" width: 150px;"},
          {key: 'collaborator_account', label: 'Numero de Cuenta' ,_style:" width: 50px;"},
          {key: 'collaborator_bank', label: 'Banco' ,_style:" width: 50px;"},
          {key: 'collaborator_salary', label: 'Monto a Pagar' ,_style:" width: 50px;"},
        ],      
      genericSelect: "",
      genericsOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_customer = localStorage.getItem("is_customer");

      if(is_customer == "false"){
        let is_kam = localStorage.getItem("is_kam");

        if(is_kam == "false"){
          window.location.href = "/#/home"; this.loaded(); return;
        }
        else{
          window.location.href = "/#/welcome"; this.loaded(); return;
        }
      }
      else{
        window.location.href = "/#/customer-graphs/general"; this.loaded(); return;
      }
    }

    let response = await ws.getGenerics(); 
    if(response.type == "success"){
      this.genericsOptions = await this.$parseSelectOptions(response.data, "id", "name",[{"label": "Todos","value":""}]);
      if(response.data.length > 0){
        this.genericSelect = this.genericsOptions[0].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          collaborator_id : this.genericSelect,
          month : this.monthSelect,
        };       
        let response = await ws.getGenericsBranches(filters);
        if(response.type == "success"){
          this.visits = response.data.visits;
        }
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
.table-support .table th {
  padding: .25rem !important;
}
.table-support .table td {
  padding: .5rem !important;
}
</style>
